import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {Api} from 'src/smoothr-web-app-core/api/api';
import Order from 'src/smoothr-web-app-core/models/Order';
import Payment from 'src/smoothr-web-app-core/models/Payment';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import {numberToCurrency, sleep} from 'src/smoothr-web-app-core/utils/utils';
import validator from 'validator';

@Component({
	selector: 'app-request-email',
	templateUrl: './request-email.component.html',
	styleUrls: ['./request-email.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestEmailComponent {
	public order: Order;
	public venue: Venue;
	public payment: Payment;
	numberToCurrency = numberToCurrency;
	OrderUtils = OrderUtils;

	email = '';
	isValidEmail = true;
	loading = new BehaviorSubject(false);
	isSuccessPage = new BehaviorSubject(false);
	constructor(
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {}

	static async show(modalCtrl: ModalController, order: Order, payment: Payment): Promise<boolean> {
		const modal = await modalCtrl.create({
			cssClass: 'payment-info-modal ',
			component: RequestEmailComponent,
			componentProps: {
				order,
				payment
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();

		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	resetValidation() {
		this.isValidEmail = true;
	}

	validate() {
		if (!validator.isEmail(this.email)) {
			this.isValidEmail = false;
			return;
		} else {
			this.isValidEmail = true;
		}
	}
	async sendRequest() {
		this.validate();
		if (!this.isValidEmail) {
			this.snackbarCtrl.open(this.translate.instant('errors.email'), null, {
				duration: 5000
			});
			return;
		}
		this.loading.next(true);
		try {
			const response = Api.sendCustomerInvoiceEmail({email: this.email.toLowerCase()}, this.payment._id, 'en');
			if (response) {
				await sleep(1000);
				this.isSuccessPage.next(true);
			}
			this.loading.next(false);
			await sleep(2000);

			await this.closeModalSuccess();
		} catch (e) {
			this.snackbarCtrl.open('Sorry, we have a problem with sending email...', null, {
				duration: 5000
			});
			console.log(e);
			await this.closeModalError();
			this.isSuccessPage.next(false);
		}
	}
	async closeModalError() {
		this.modalCtrl.dismiss(false);
	}
	async closeModalSuccess() {
		await sleep(2000);
		this.cdr.markForCheck();

		this.modalCtrl.dismiss(true);
	}
}
