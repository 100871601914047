import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {SharedModuleModule} from '../../shared-module/shared-module.module';
import {DeleteUserPage} from './delete-user.page';

const routes: Routes = [
	{
		path: '',
		component: DeleteUserPage
	}
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule],
	declarations: [DeleteUserPage]
})
export class DeleteUserPageModule {}
